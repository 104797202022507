.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiAppBar-root {
  height: 55px;
  margin-bottom: 20px;
}

.Menu-item {
  color: white;
  padding: 0 10px;
  text-decoration: none;
}

.Menu-item.active {
  border-bottom: 1px solid whitesmoke;
}

.Menu-item-mobile {
  width: 100%;
  text-align: center;
  text-decoration: none;
}

.Menu-item-mobile.active {
  background-color: grey;
}

.Block-text {
  padding: 18px;
  border: 1px solid lightgray;
  border-radius: 5px;
  display: inline-block;
}

.Writingpage {
}

.Writingpage-details {
  height: calc(100vh - 60px - 100px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Submitbutton {
  padding: 14px !important;
  margin-top: 24px !important;
}

.Writetextbox textarea {
  height: calc(100vh - 230px - 100px) !important;
}

.FeedbackWaitMessage {
  height: 100px;
}

.StudentDashboardTable {
  height: calc(100vh - 180px);
  width: "100%";
}

.left-aligned-header {
  --offset-x: 100px;
  display: flex;
  position: relative;
  left: var(--offset-x);
  align-items: center;
  width: calc(100% - var(--offset-x));
}

.PromptDescriptionTextBox {
  /* height: 190px !important;
  overflow: scroll; */
}

#Feedback {
  height: 100vh;
  overflow: hidden;
}

#Feedback iframe {
  height: 100vh;
}

#QuickSightDashboard {
  height: calc(100vh - 64px);
  margin-top: -20px;
}
